import ajax from './ajax'
export default {
  // 用户管理-登陆、用户设置
  UserLogin: {
    login (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/green/security/loginCourseNew', data, success, false, errFn)
    },
    loginWithCode (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/green/security/loginWithCode', data, success, false, errFn)
    },
    validCodeChange (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/green/security/validCodeKey', data, success, false, errFn)
    },
    listZoneTeacher (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/teacher/listZoneTeacher', data, success, false, errFn)
    },
    changeLoginZoneTeacher2 (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/teacher/changeLoginZoneTeacher2?zoneTeacherId=' + data.zoneTeacherId, data, success, false, errFn)
    },
    // 获取登录接口老师的信息
    getTeacherAccountInfoByTeacherId (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/teacher/getTeacherAccountInfoByTeacherId', data, success, false, errFn)
    },
    // 修改密码
    changPassword (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/teacher/changPassword', data, success, false, errFn)
    },
    loginValidateCodeByManager (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/green/security/loginValidateCodeNew', data, success, false, errFn)
    },
    // 重置密码
    resetPassword (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/green/security/resetPassword', data, success, false, errFn)
    },
    // 获取OTO权限，是否开通
    zoneFeatureIsBroadcast (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/zone/zoneFeatureIsBroadcast', data, success, false, errFn)
    },
    feedbackSave (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/feedback/addFeedbackSchool', data, success, false, errFn)
    },
    getMenus (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/system/getMenus', data, success, false, errFn)
    },
    lingjiaoVerifyAccount (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/zone/lingjiaoVerifyAccount', data, success, false, errFn)
    },
  },
  // 学生管理接口
  StudentManagement: {
    getStudentList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/webZonePageAllZoneStudent', data, success, false, errFn)
    },
    postStudentBatchUpdate (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/studentBatchUpdate', data, success, false, errFn)
    },
    // 新增学生信息
    onStudentAdd (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/addZoneStudent', data, success, false, errFn)
    },
    // 编辑学生信息
    onStudentUpdate (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/updateZoneStudent', data, success, false, errFn)
    },
    // 选择家长
    getStudentParents (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/parent/getZoneParents', data, success, false, errFn)
    },
    // 删除学生
    onStudentDelete (data, success, errFn) {
      ajax.call(this, 'delete', 'app/rest/school/auth/student/removeZoneStudentById', data, success, false, errFn)
    },
    // 重新激活
    onStudentActive (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/activationZoneStudent', data, success, false, errFn)
    },
    // 删除学生
    onDeleteByZoneStudentId (data, success, errFn) {
      ajax.call(this, 'delete', 'app/rest/school/auth/student/deleteByZoneStudentId', data, success, false, errFn)
    },
    // 删除学生
    batchDeleteByZoneStudentId (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/batchDeleteByZoneStudentId', data, success, false, errFn)
    },
    exportZoneStudentsInfo (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/exportZoneStudentsInfo', data, success, false, errFn)
    },
    getStudyClass  (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studyClass/getStudyClassList', data, success, false, errFn)
    },

  },
  ClassManagement: {
    getZoneStudentDataRemind (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/messageRemind/getZoneStudentDataRemind', data, success, false, errFn)
    },
    getClassList (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studyClass/getStudyClass', data, success, false, errFn)
    },
    //  添加班级
    addClassList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studyClass/addStudyClass', data, success, false, errFn)
    },
    //  编辑班级
    updateClassList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studyClass/updateStudyClass', data, success, false, errFn)
    },
    //  删除班级
    removeStudyClassById (data, success, errFn) {
      ajax.call(this, 'delete', 'app/rest/school/auth/studyClass/removeStudyClassById', data, success, false, errFn)
    },
    // 查询班级详情
    getStudyClassById (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studyClass/getStudyClassById', data, success, false, errFn)
    },
    // 学生入班
    pageSchoolZoneStudents (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/pageSchoolZoneStudents', data, success, false, errFn)
    }
  },
  TeacherManagement: {
    // 在职老师
    getTeacherList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/teacher/pageZoneTeacherList', data, success, false, errFn)
    },
    // 离职老师
    getTeacherQuitList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/teacher/pageQuitZoneTeacherList', data, success, false, errFn)
    },
    getStudyClassByTeacherId (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studyClass/getStudyClassByTeacherId', data, success, false, errFn)
    },
    // 编辑保存老师信息
    onTeacherSave (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/teacher/updateZoneTeacher', data, success, false, errFn)
    },
    // 新增保存老师信息
    onTeacherAdd (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/teacher/addZoneTeacher', data, success, false, errFn)
    },
    // 删除老师
    onTeacherDelete (data, success, errFn) {
      ajax.call(this, 'delete', 'app/rest/school/auth/teacher/removeZoneTeacherById', data, success, false, errFn)
    },
    // 重新入职
    activationZoneTeacher (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/teacher/activationZoneTeacher', data, success, false, errFn)
    },
    getTeacherIdCar (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/teacher/checkIdentityCard', data, success, false, errFn)
    }
  },
  StudentAttendance: {
    getStudentAttendanceList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/pageZoneStudentAttendance', data, success, false, errFn)
    },
    exportStudentAttendance (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studentAttendance/exportStudentAttendance', data, success, false, errFn)
    }
  },
  TeacherAttendance: {
    getTeacherAttendanceList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/student/pageZoneTeacherAttendance', data, success, false, errFn)
    },
    exportTeacherAttendance (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/teacherAttendance/exportTeacherAttendance', data, success, false, errFn)
    }
  },
  File: {
    // 资料分类
    getAllEnabledFileBases (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/filepack/getAllEnabledFileBases', data, success, false, errFn)
    },
    // 学期
    getAllSemesters (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/filepack/getAllSemesters', data, success, false, errFn)
    },
    // 标签
    getAllFileTag (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/filepack/getFileTagByBaseId', data, success, false, errFn)
    },
    // 最新
    getLastFilePacks (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/filepack/getLastFilePacks', data, success, false, errFn)
    },
    // 热门
    getHotFilePacks (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/filepack/getHotFilePacks', data, success, false, errFn)
    },
    pageFilePackModel (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/filepack/pageFilePackModel', data, success, false, errFn)
    },
    getFilePackById (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/filepack/getFilePackById', data, success, false, errFn)
    },
    getRootFolders (data, success, errFn) {
      ajax.call(this, 'get', 'fm/rest/filemanager/getRootFolders', data, success, false, errFn)
    },
    getFileItems (data, success, errFn) {
      ajax.call(this, 'get', 'fm/rest/filemanager/getFileItems', data, success, false, errFn)
    },
    downloadFile (data, success, errFn) {
      ajax.call(this, 'post', 'fm/rest/filemanager/downloadFile', data, success, false, errFn)
    },
    downloadFileAll (data, success, errFn) {
      // ajax.call(this, 'post', 'fm/rest/filemanager/downloadZipFile', data, success, false, errFn)
      ajax.call(this, 'get', 'fm/rest/filemanager/getRootZipFile', data, success, false, errFn)
    },
    saveFilePackDownload (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/filepack/saveFilePackDownload', data, success, false, errFn)
    }
  },
  FileDowm: {
    getTopCategory (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/file/category/getTopCategory', data, success, false, errFn)
    },
    getCategory (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/file/category/getCategoryByTypeId', data, success, false, errFn)
    },
    getPapers (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/file/paper/getPapers', data, success, false, errFn)
    },
    getZoneTeachers (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/teacher/getZoneTeachers', data, success, false, errFn)
    },
    addPerformanceList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/file/performance/addPerformanceList', data, success, false, errFn)
    },
    getPerformance (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/file/performance/getPerformance', data, success, false, errFn)
    },
    addDownloadNum (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/file/paper/addDownloadNum', data, success, false, errFn)
    },
    pageFileDirectoryDetails (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/fileManagerNew/pageFileDirectoryDetails', data, success, false, errFn)
    },
    updateExamPaperStatus (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/fileManagerNew/updateFileIsEnable', data, success, false, errFn)
    },
    getTree (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/fileManagerNew/queryFileDirectoryTree', data, success, false, errFn)
    },
    getChildFileDirectoryTreeItems (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/fileManagerNew/getChildFileDirectoryTreeItems', data, success, false, errFn)
    },
    addFileDirectory (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/fileManagerNew/addFileDirectory', data, success, false, errFn)
    },
    editFileDirectory (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/fileManagerNew/updateFileDirectory', data, success, false, errFn)
    },
    updateFileDel (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/fileManagerNew/updateFileDel', data, success, false, errFn)
    },
    deleteFileDirectory (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/fileManagerNew/deleteFileDirectory', data, success, false, errFn)
    },
    deleteDirectoryFile (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/fileManagerNew/deleteDirectoryFile', data, success, false, errFn)
    },
    saveFileDirectoryDetails (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/fileManagerNew/saveFileDirectoryDetails?fileDirectoryId=' + data.fileDirectoryId, data.fileList, success, false, errFn)
    },
    batchUpdateFileDel (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/fileManagerNew/batchUpdateFileDel?del=' + data.del, data.fileids, success, false, errFn)
    },
    addFileDownloadNum (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/fileManagerNew/addFileDownloadNum?fileId=' + data.fileId, {}, success, false, errFn)
    }

  },
  Home: {
    // 在校员工情况
    getZoneTeacherByPosition (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/teacher/getZoneTeacherByPosition', data, success, false, errFn)
    },
    // 在校员工情况
    getZoneStudentByGrade (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/student/getZoneStudentByGrade', data, success, false, errFn)
    }
  },
  Course: {
    CourseWareQuery: {
      pageZoneCourseware (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/pageZoneCourseware', data, success, false, errFn)
      }
    },
    lessonManagement: {
      pageFlowBill (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/flowBill/pageFlowBill', data, success, false, errFn)
      },
      // 安亲豆
      flowRecharge (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/flowBill/flowRecharge', data, success, false, errFn)
      },
      getClassroomActualUsers (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/classroom/getClassroomActualUsers', data, success, false, errFn)
      },
      getBroadcastEnterRecordsByRoomIdAndUserId (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/classroom/getBroadcastEnterRecordsByRoomIdAndUserId', data, success, false, errFn)
      }
    },
    classManagement: {
      pageOnlineClass (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/pageOnlineClass', data, success, false, errFn)
      },
      // 线上班级详情
      getOnlineClassByIdFetchData (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/onlineClass/getOnlineClassByIdFetchData', data, success, false, errFn)
      },
      // 线上课节
      pageClassroom (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/pageClassroom', data, success, false, errFn)
      },
      // 查询所有学生app/rest/school/auth/student/webZonePageZoneStudent
      webZonePageZoneStudent (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/student/webZonePageZoneStudent', data, success, false, errFn)
      },
      // 给班级添加学生
      changeOnlineClassStudents (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/changeOnlineClassStudents', data, success, false, errFn)
      },
      // 授课老师
      getZoneTeacherList (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/teacher/getZoneTeacherList', data, success, false, errFn)
      },
      // 添加课节
      addOnlineClassroom (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/addOnlineClassroom', data, success, false, errFn)
      },
      // 删除班级
      removeOnlineClassById (data, success, errFn) {
        ajax.call(this, 'delete', 'app/rest/school/auth/onlineClass/removeOnlineClassById', data, success, false, errFn)
      },
      // 删除在线课堂学生
      removeStudentForOnlineClass (data, success, errFn) {
        ajax.call(this, 'delete', 'app/rest/school/auth/onlineClass/removeStudentForOnlineClass', data, success, false, errFn)
      },
      // 修改课节
      updateOnlineClassroom (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/updateOnlineClassroom', data, success, false, errFn)
      },
      // 删除课节
      removeClassroomById (data, success, errFn) {
        ajax.call(this, 'delete', 'app/rest/school/auth/onlineClass/removeClassroomById', data, success, false, errFn)
      },
      // 是否启用课节
      enableClassroom (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/enableClassroom', data, success, false, errFn)
      },
      // 已添加课节包列表
      getCoursewaresByRoomId (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getCoursewaresByRoomId', data, success, false, errFn)
      },
      // 删除课件
      removeCoursewareForClassroom (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/removeCoursewareForClassroom', data, success, false, errFn)
      },
      // 树节点
      getRootCourseCategoryTree (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getRootCourseCategoryTree', data, success, false, errFn)
      },
      // 子节点
      getAuthorizePublicChildCourseCategoryTreeItems (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getAuthorizePublicChildCourseCategoryTreeItems', data, success, false, errFn)
      },
      // 子节点
      getChildCourseCategoryTreeItems (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getChildCourseCategoryTreeItems', data, success, false, errFn)
      },
      // 子节点
      getPublicChildCourseCategoryTreeItems (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getPublicChildCourseCategoryTreeItems', data, success, false, errFn)
      },
      // 查询课件
      pageCourseware (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/pageCourseware', data, success, false, errFn)
      },
      // 修改课节
      updateCoursewareForClassroom (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/updateCoursewareForClassroom', data, success, false, errFn)
      },
      // 学生监控-列表
      webStudentClassroomsByRoomId (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/studentClassroom/webStudentClassroomsByRoomId', data, success, false, errFn)
      },
      // 获取各班级的学生人数
      getZoneStudentListByGrade (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/student/getZoneStudentListByGrade', data, success, false, errFn)
      },
      // 收费标准
      getAllRoomTypeProperties (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/onlineClass/getAllRoomTypeProperties', data, success, false, errFn)
      },
      // 各年级人数
      countZoneStudentByGrade (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/student/countZoneStudentByGrade', data, success, false, errFn)
      },
      // 获取模板树节点
      getChooseCourseTree (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getChooseCourseTree', data, success, false, errFn)
      },
      // 获取树的子节点
      getChildChooseCourseTreeItems (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getChildChooseCourseTreeItems', data, success, false, errFn)
      },
      pageCourse (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/course/pageCourse', data, success, false, errFn)
      },
      // 新增线上班级
      addOnlineClass (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/onlineClass/addOnlineClass', data, success, false, errFn)
      },
      // 通过ID 查询课程
      getCourseById (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/course/getCourseById', data, success, false, errFn)
      },
      // 通过ID 查询课程详细
      getCourseSectionsByCourseId (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/courseSection/getCourseSectionsByCourseId', data, success, false, errFn)
      }
    },
    courseManagement: {
      getPublicRootCourseCategoryTree (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getPublicRootCourseCategoryTree', data, success, false, errFn)
      },
      getZoneRootCourseCategoryTree (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getZoneRootCourseCategoryTree', data, success, false, errFn)
      },
      // 总部子节点
      getPublicChildCourseCategoryTreeItems (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getPublicChildCourseCategoryTreeItems', data, success, false, errFn)
      },
      // 校区子节点
      getChildCourseCategoryTreeItems (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getChildCourseCategoryTreeItems', data, success, false, errFn)
      },
      pageCourseSection (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/courseSection/pageCourseSection', data, success, false, errFn)
      },
      pageCourseware (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/pageCourseware', data, success, false, errFn)
      },
      getCourseById (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/course/getCourseById', data, success, false, errFn)
      },
      addCourseSection (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/courseSection/addCourseSection', data, success, false, errFn)
      },
      updateCourseSection (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/courseSection/updateCourseSection', data, success, false, errFn)
      },
      removeCourseSectionById (data, success, errFn) {
        ajax.call(this, 'delete', 'app/rest/school/auth/course/courseSection/removeCourseSectionById', data, success, false, errFn)
      },
      enableCourseSection (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/courseSection/enableCourseSection', data, success, false, errFn)
      },
      addCourseSegment (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/courseSegment/addCourseSegment', data, success, false, errFn)
      },
      updateCourseSegment (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/courseSegment/updateCourseSegment', data, success, false, errFn)
      },
      removeCourseSegmentById (data, success, errFn) {
        ajax.call(this, 'delete', 'app/rest/school/auth/course/courseSegment/removeCourseSegmentById', data, success, false, errFn)
      },
      // 获取所有课节
      getCourseSectionsByCourseId (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/courseSection/getCourseSectionsByCourseId', data, success, false, errFn)
      },
      updateCourseware (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/updateCourseware', data, success, false, errFn)
      },
      removeCoursewareById (data, success, errFn) {
        ajax.call(this, 'delete', 'app/rest/school/auth/course/removeCoursewareById', data, success, false, errFn)
      },
      enableCourseware (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/enableCourseware', data, success, false, errFn)
      },
      submitDocumentTranscodeTask (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/submitDocumentTranscodeTask', data, success, false, errFn)
      },
      queryDocumentTranscodeTaskResult (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/queryDocumentTranscodeTaskResult', data, success, false, errFn)
      },
      getZoneCourseCategoryTree (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getZoneCourseCategoryTree', data, success, false, errFn)
      },
      addCourseCategory (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/addCourseCategory', data, success, false, errFn)
      },
      removeCourseCategoryById (data, success, errFn) {
        ajax.call(this, 'delete', 'app/rest/school/auth/course/removeCourseCategoryById', data, success, false, errFn)
      },
      addCourseware (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/addCourseware', data, success, false, errFn)
      },
      getCourseCategoryById (data, success, errFn) {
        ajax.call(this, 'get', 'app/rest/school/auth/course/getCourseCategoryById', data, success, false, errFn)
      },
      updateCourseCategory (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/updateCourseCategory', data, success, false, errFn)
      },
      addCourse (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/course/addCourse', data, success, false, errFn)
      },
      updateCourse (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/course/course/updateCourse', data, success, false, errFn)
      },
      removeCourseById (data, success, errFn) {
        ajax.call(this, 'delete', 'app/rest/school/auth/course/course/removeCourseById', data, success, false, errFn)
      }
    }
  },
  Evaluation: {
    findBySchool (data, success, errFn) {
      ajax.call(this, 'get', 'focus/user/findBySchool', data, success, false, errFn)
    },
    findWechatUser (data, success, errFn) {
      ajax.call(this, 'get', 'focus/wechat/findWechatUser', data, success, false, errFn)
    },
    findReportByUserId (data, success, errFn) {
      ajax.call(this, 'get', 'focus/report/findReportByUserId', data, success, false, errFn)
    },
    findParentReport (data, success, errFn) {
      ajax.call(this, 'get', 'focus/parent/findParentReport', data, success, false, errFn)
    },
    findLinkman (data, success, errFn) {
      ajax.call(this, 'get', 'focus/linkman/findLinkman', data, success, false, errFn)
    },
    addLinkman (data, success, errFn) {
      ajax.call(this, 'post', 'focus/linkman/addLinkman', data, success, false, errFn)
    },
    updateLinkman (data, success, errFn) {
      ajax.call(this, 'post', 'focus/linkman/updateLinkman', data, success, false, errFn)
    },
    deleteWechatUser (data, success, errFn) {
      ajax.call(this, 'post', 'focus/wechat/deleteWechatUser', data, success, false, errFn)
    },
    findStudentAndReportNum (data, success, errFn) {
      ajax.call(this, 'get', 'focus/user/findStudentAndReportNum', data, success, false, errFn)
    }
  },
  teacherAssessment: {
    getTopCategory (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/exam/category/getTopCategory', data, success, false, errFn)
    },
    getCategory (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/exam/category/getCategory', data, success, false, errFn)
    },
    getPapers (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/exam/paper/getPapers', data, success, false, errFn)
    },
    getZoneTeachers (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/teacher/getZoneTeachers', data, success, false, errFn)
    },
    addPerformanceList (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/exam/performance/addPerformanceList', data, success, false, errFn)
    },
    getPerformance (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/exam/performance/getPerformance', data, success, false, errFn)
    },
    addDownloadNum (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/exam/paper/addDownloadNum', data, success, false, errFn)
    }
  },
  User: {
    getUsers (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/system/getUsers', data, success, false, errFn)
    },
    bindUserRole (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/system/bindUserRole', data, success, false, errFn)
    }
  },
  Role: {
    getRoles (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/system/getRoles', data, success, false, errFn)
    },
    createRole (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/system/createRole', data, success, false, errFn)
    },
    updateRole (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/system/updateRole', data, success, false, errFn)
    },
    getMenus (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/system/getMenus', data, success, false, errFn)
    },
    deleteRole (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/system/deleteRole', data, success, false, errFn)
    },
    bindRolePermission (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/system/bindRolePermission', data, success, false, errFn)
    },
    getPermissionTree (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/system/getPermissionTree', data, success, false, errFn)
    }
  },
  Authority: {
    getPermissions (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/system/getPermissions', data, success, false, errFn)
    },
    createPermission (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/system/createPermission', data, success, false, errFn)
    },
    updatePermission (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/system/updatePermission', data, success, false, errFn)
    },
    deletePermission (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/system/deletePermission', data, success, false, errFn)
    }
  },
  Order: {
    getStudentOrder (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentOrder/getStudentOrder', data, success, false, errFn)
    },
    getStudentOrderAmountStatistics (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentOrder/getStudentOrderAmountStatistics', data, success, false, errFn)
    },
    exportStudentOrder (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studentOrder/exportStudentOrder', data, success, false, errFn)
    },
    getStudyClassByZoneId (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studyClass/getStudyClassByZoneId', data, success, false, errFn)
    },
    addStudentOrder (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentOrder/addStudentOrder', data, success, false, errFn)
    },
    deleteStudentOrder (data, success, errFn) {
      ajax.call(this, 'delete', 'app/rest/school/auth/studentOrder/deleteStudentOrder', data, success, false, errFn)
    },
    updateStudentOrder (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentOrder/updateStudentOrder', data, success, false, errFn)
    },
    expirationReminder (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studentOrder/expirationReminder', data, success, false, errFn)
    },
    addStudentRefundForm (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentOrder/addStudentRefundForm', data, success, false, errFn)
    },
    getStudentRefundFormPage (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentOrder/getStudentRefundFormPage', data, success, false, errFn)
    },
    getFinancialAccountPage (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/financialAccount/financialAccountPage', data, success, false, errFn)
    },
    exportFinancialAccountSpending (data, success, errFn) {
      // data = Object.assign(data, {ajaxType: 'form'})
      ajax.call(this, 'post', 'app/rest/school/auth/financialAccount/exportFinancialAccountSpending', data, success, false, errFn)
    },
    postExpenditureStatistics (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/financialAccount/getExpenditureStatistics', data, success, false, errFn)
    },

    onUpdateFinancialAccount (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/financialAccount/updateFinancialAccount', data, success, false, errFn)
    },
    onAddFinancialAccount (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/financialAccount/addFinancialAccount', data, success, false, errFn)
    },
    removeFinancialAccountById (data, success, errFn) {
      ajax.call(this, 'delete', 'app/rest/school/auth/financialAccount/removeFinancialAccountById', data, success, false, errFn)
    },
  },
  Clue: {
    getStudyClassByZoneId (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studyClass/getStudyClassByZoneId', data, success, false, errFn)
    },
    getClues (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentClue/pageClues', data, success, false, errFn)
    },
    createClue (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentClue/createClue', data, success, false, errFn)
    },
    updateClue (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentClue/updateClue', data, success, false, errFn)
    },
    studentClueAllocateTeacher (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentClue/studentClueAllocateTeacher', data, success, false, errFn, 'sequence')
    },

    deleteClue (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentClue/deleteClue', data, success, false, errFn)
    },
    createApplication (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentClue/createApplication', data, success, false, errFn)
    },
    getClueDetails (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studentClue/getClue', data, success, false, errFn)
    },
    createClueRecord (data, success, errFn) {
      ajax.call(this, 'post', 'app/rest/school/auth/studentClue/createClueRecord', data, success, false, errFn)
    },
    exportStudentClues (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/studentClue/exportStudentClues', data, success, false, errFn)
    }
  },
  Mall: {
    Commodity: {
      getProduct (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/pageProduct', data, success, false, errFn)
      },
      addProduct (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/addScoreProduct', data, success, false, errFn)
      },
      updateScoreProduct (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/updateScoreProduct', data, success, false, errFn)
      },
      deleteScoreProduct (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/deleteScoreProduct', data, success, false, errFn)
      },
      exportProduct (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/exportProduct', data, success, false, errFn)
      }
    },
    Record: {
      getProductRecord (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/pageProductRecord', data, success, false, errFn)
      },
      acceptExchange (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/acceptExchange', data, success, false, errFn)
      },
      refuseExchange (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/refuseExchange', data, success, false, errFn)
      },
      exportProductRecord (data, success, errFn) {
        ajax.call(this, 'post', 'app/rest/school/auth/product/exportProductRecord', data, success, false, errFn)
      }
    }
  },
  DoubleTeacher: {
    login (data, success, errFn) {
      const params = new URLSearchParams();
      params.append('username', data);
      params.append('password', 'angelbell.jiaxiao');
      params.append('type', 'account');
      ajax.call(this, 'post', 'login', params, success, false, errFn, 'double-teacher');
    },
    updateTerminal (data, success, errFn) {
      ajax.call(this, 'put', 'user', data, success, false, errFn, 'double-teacher')
    },
    findAllBymerchantId (data, success, errFn) {
      ajax.call(this, 'get', 'merchantClassType/findAllBymerchantId', data, success, false, errFn, 'double-teacher')
    },
    getAccountData (data, success, errFn) {
      ajax.call(this, 'get', 'user/list', data, success, false, errFn, 'double-teacher')
    },
    findTerminalListByCampusIdPage (data, success, errFn) {
      ajax.call(this, 'get', 'user/findTerminalListByCampusIdPage', data, success, false, errFn, 'double-teacher')
    },
    deleteBind (data, success, errFn) {
      ajax.call(this, 'post', 'user/deleteBind', data, success, false, errFn, 'double-teacher')
    },
    defaultClass (data, success, errFn) {
      ajax.call(this, 'post', 'user/defaultClass', data, success, false, errFn, 'double-teacher')
    },
    updateClassRoomName (data, success, errFn) {
      ajax.call(this, 'put', 'user', data, success, false, errFn, 'double-teacher')
    },
    packageAuthsPage (data, success, errFn) {
      ajax.call(this, 'get', 'packageAuths/page', data, success, false, errFn, 'double-teacher')
    },
    getUserDetails (data, success, errFn) {
      ajax.call(this, 'get', 'user/code', data, success, false, errFn, 'double-teacher')
    },
    getPackageProductDetails (data, success, errFn) {
      ajax.call(this, 'get', 'package/product', data, success, false, errFn, 'double-teacher')
    },
    getAllTermList (data, success, errFn) {
      ajax.call(this, 'get', 'term/getAll', data, success, false, errFn, 'double-teacher')
    },
    getClassRoomList (data, success, errFn) {
      ajax.call(this, 'get', 'classRoom/list', data, success, false, errFn, 'double-teacher')
    },
    getTermYearMerchantList (data, success, errFn) {
      ajax.call(this, 'get', 'termYearMerchant/findByMerchant', data, success, false, errFn, 'double-teacher')
    },
    updateTerminalBinding (data, success, errFn) {
      ajax.call(this, 'post', 'user/deleteBind', data, success, false, errFn, 'double-teacher')
    },
    bindClassRoom (data, success, errFn) {
      ajax.call(this, 'post', 'user/bindClassRoom', data, success, false, errFn, 'double-teacher')
    },
    getTerminalList (data, success, errFn) {
      ajax.call(this, 'get', 'user/list/nopage', data, success, false, errFn, 'double-teacher')
    },
    getClassTimetableList (data, success, errFn) {
      ajax.call(this, 'get', 'timeTable/timetableClassClassify', data, success, false, errFn, 'double-teacher')
    },
    getTimetableClassList (data, success, errFn) {
      ajax.call(this, 'get', 'timeTable/timetableClass/list', data, success, false, errFn, 'double-teacher')
    },
    getTimetableClassDetails (data, success, errFn) {
      ajax.call(this, 'get', 'timeTable/timetableClass/findById', data, success, false, errFn, 'double-teacher')
    },
    getTagtypeList (data, success, errFn) {
      ajax.call(this, 'get', 'tagtype/list', data, success, false, errFn, 'double-teacher')
    },
    getTagList (data, success, errFn) {
      ajax.call(this, 'get', 'tag/list', data, success, false, errFn, 'double-teacher')
    },
    getProductList (data, success, errFn) {
      ajax.call(this, 'get', 'tagProductRelation/product/list', data, success, false, errFn, 'double-teacher')
    },
    getLessonList (data, success, errFn) {
      ajax.call(this, 'get', 'lesson/list/nopage/findByCourseId', data, success, false, errFn, 'double-teacher')
    },
    putTimetableLesson (data, success, errFn) {
      ajax.call(this, 'put', 'timeTable/timetableLesson', data, success, false, errFn, 'double-teacher')
    },
    deleteLesson (data, success, errFn) {
      ajax.call(this, 'delete', 'timeTable/deleteLesson', data, success, false, errFn, 'double-teacher')
    },
    createClassTermTimetable (data, success, errFn) {
      ajax.call(this, 'post', 'timeTable/timetableClass', data, success, false, errFn, 'double-teacher')
    },
    getTermMerchant (data, success, errFn) {
      ajax.call(this, 'get', 'termMerchant/findByMerchant/' + data.merchantId, data.param, success, false, errFn, 'double-teacher')
    },
    updateTerm (data, success, errFn) {
      ajax.call(this, 'put', 'classRoom/updateTerm', data, success, false, errFn, 'double-teacher')
    },
    getEducationStage (data, success, errFn) {
      ajax.call(this, 'get', 'educationStage/findNormal', data, success, false, errFn, 'double-teacher')
    },
    updateClassRoom (data, success, errFn) {
      ajax.call(this, 'put', 'classRoom', data, success, false, errFn, 'double-teacher')
    },
    updateClassCode (data, success, errFn) {
      ajax.call(this, 'put', 'classRoom/updateCode', data, success, false, errFn, 'double-teacher')
    },
    upOneClass (data, success, errFn) {
      ajax.call(this, 'post', 'classRoom/upOneClass', data, success, false, errFn, 'double-teacher')
    },
    logout (data, success, errFn) {
      ajax.call(this, 'post', 'logout', data, success, false, errFn, 'double-teacher')
    },
  },
}
